import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
} from 'recharts';

export interface ProbesDataPoint {
  date: string;
  evalId: string;
  succeeded: number;
  failed: number;
  total: number;
}

interface ProbesChartProps {
  data: ProbesDataPoint[];
}

export default function ProbesChart({ data }: ProbesChartProps) {
  const navigate = useNavigate();

  const sortedData = useMemo(() => {
    if (!data) {
      return [];
    }
    return [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [data]);

  const handleDotClick = (dataPoint: any) => {
    if (dataPoint.payload?.evalId) {
      navigate(`${ROUTES.redteam.report}/${dataPoint.payload.evalId}`);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={sortedData}>
        <CartesianGrid strokeDasharray="3 3" />

        {sortedData.map((dataPoint, index) => (
          <ReferenceLine
            key={`${dataPoint.date}-${index}`}
            x={dataPoint.date}
            stroke="#666"
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        ))}

        <XAxis
          dataKey="date"
          tick={{ fontSize: 10 }}
          type="category"
          tickFormatter={(value) => new Date(value).toLocaleDateString()}
          ticks={[...new Set(sortedData.map((d) => new Date(d.date).toLocaleDateString()))]}
          interval={0}
          minTickGap={50}
        />
        <YAxis width={40} domain={[0, 'dataMax']} tick={{ fontSize: 12 }} />
        <Tooltip
          formatter={(value: number, name: string) => {
            const label =
              name === 'succeeded'
                ? 'Attack Succeeded'
                : name === 'failed'
                  ? 'Attack Failed'
                  : 'Total';
            return [value, label];
          }}
        />
        <Legend />
        <Area
          type="monotone"
          dataKey="succeeded"
          stackId="1"
          stroke="#d32f2f"
          fill="#d32f2f"
          name="Attack Succeeded"
          isAnimationActive={false}
          dot={{
            stroke: '#d32f2f',
            strokeWidth: 3,
            r: 2,
            fill: 'white',
            cursor: 'pointer',
            onClick: (_, payload) => handleDotClick(payload),
          }}
          activeDot={{
            stroke: '#d32f2f',
            strokeWidth: 3,
            r: 3,
            fill: 'white',
            cursor: 'pointer',
            onClick: (_, payload) => handleDotClick(payload),
          }}
        />
        <Area
          type="monotone"
          dataKey="failed"
          stackId="1"
          stroke="#388e3c"
          fill="#388e3c"
          name="Attack Failed"
          isAnimationActive={false}
          dot={{
            stroke: '#388e3c',
            strokeWidth: 3,
            r: 2,
            fill: 'white',
            cursor: 'pointer',
            onClick: (_, payload) => handleDotClick(payload),
          }}
          activeDot={{
            stroke: '#388e3c',
            strokeWidth: 3,
            r: 3,
            fill: 'white',
            cursor: 'pointer',
            onClick: (_, payload) => handleDotClick(payload),
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
