import Markdown from 'react-markdown';
import { Card, Typography } from '@mui/material';

export type SuggestionType = {
  title: string;
  remediation: string;
  timeframe: string;
};

interface SuggestionProps {
  suggestion: SuggestionType;
}

export default function Suggestion({ suggestion }: SuggestionProps) {
  return (
    <Card
      sx={{
        mb: 3,
        p: 3,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: 3,
          borderColor: 'primary.main',
          transform: 'translateY(-2px)',
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: 'primary.main',
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          '&::before': {
            content: '""',
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'primary.main',
            display: 'inline-block',
          },
        }}
      >
        {suggestion.title}
      </Typography>

      <Markdown
        components={{
          p: ({ children }) => (
            <Typography
              component="p"
              sx={{
                my: 1,
                lineHeight: 1.6,
              }}
            >
              {children}
            </Typography>
          ),
        }}
      >
        {suggestion.remediation}
      </Markdown>
    </Card>
  );
}
