import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { callApi } from '@cloud-ui/utils/api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function VerifyEmailPage() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [apiKey, setApiKey] = useState<string | null>(null);
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setStatus('error');
        return;
      }

      try {
        const response = await callApi('/email-verification/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (response.ok) {
          const data = await response.json();
          setApiKey(data.apiKey);
          setStatus('success');
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setStatus('error');
      }
    };

    verifyEmail();
  }, [token]);

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return (
          <>
            <CircularProgress size={48} sx={{ mb: 2 }} />
            <Typography variant="h6">Verifying your email address...</Typography>
          </>
        );
      case 'success':
        return (
          <>
            <CheckCircleIcon color="success" sx={{ fontSize: 48, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Email verified successfully!
            </Typography>
            {apiKey && (
              <Box sx={{ mt: 2, mb: 2, width: '100%' }}>
                <Typography variant="subtitle1" gutterBottom>
                  Your API Key:
                </Typography>
                <Paper
                  sx={{
                    p: 2,
                    backgroundColor: 'grey.100',
                    wordBreak: 'break-all',
                    fontFamily: 'monospace',
                  }}
                >
                  {apiKey}
                </Paper>
                <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>
                  This API key is automatically set on your client. Set manually with:
                </Typography>
                <Paper
                  sx={{
                    p: 2,
                    backgroundColor: 'grey.100',
                    fontFamily: 'monospace',
                  }}
                >
                  promptfoo config set verificationKey {apiKey}
                </Paper>
              </Box>
            )}
            <Typography color="text.secondary" gutterBottom>
              You can now close this window and continue using the application.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.close()}
              sx={{ mt: 2 }}
            >
              Close Window
            </Button>
          </>
        );
      case 'error':
        return (
          <>
            <ErrorIcon color="error" sx={{ fontSize: 48, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Email verification failed
            </Typography>
            <Typography color="text.secondary" gutterBottom>
              The verification link may be invalid or expired. Please request a new verification
              email.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.close()}
              sx={{ mt: 2 }}
            >
              Close Window
            </Button>
          </>
        );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        p: 2,
        pt: 4,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 400,
          width: '100%',
        }}
      >
        {renderContent()}
      </Paper>
    </Box>
  );
}
