import { displayNameOverrides } from '@promptfoo/redteam/constants';
import type { IssueDTO } from '@shared/dto';

export function getUniqueTargets(issues: IssueDTO[]) {
  const targets = issues.map((issue) => issue.targetId);
  return Array.from(new Set(targets));
}

export function getUniqueVulnerabilities(issues: IssueDTO[]) {
  const vulnerabilities = issues
    .map((issue) => displayNameOverrides[issue.pluginId])
    .sort()
    .filter((vulnerability) => vulnerability !== undefined);
  return Array.from(new Set(vulnerabilities));
}
