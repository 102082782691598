import React, { useContext } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import DarkMode from '@app/components/DarkMode';
import LoggedInAs from '@cloud-ui/components/LoggedInAs';
import Logo from '@cloud-ui/components/Logo';
import { ROUTES } from '@cloud-ui/constants';
import type { ButtonProps } from '@mui/material';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../contexts/AuthContext';

const NavButton = styled(Button)<ButtonProps & RouterLinkProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.active': {
    backgroundColor: theme.palette.action.selected,
  },
}));

function NavLink({ href, label }: { href: string; label: string }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(href);

  return (
    <NavButton component={RouterLink} to={href} className={isActive ? 'active' : ''}>
      {label}
    </NavButton>
  );
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
  marginBottom: theme.spacing(2),
}));

const NavToolbar = styled(Toolbar)({
  justifyContent: 'space-between',
});

const NavSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
});

export default function Navigation({
  darkMode,
  onToggleDarkMode,
}: {
  darkMode: boolean;
  onToggleDarkMode: () => void;
}) {
  const { user } = useContext(AuthContext) || {};

  return (
    <>
      <StyledAppBar position="static" elevation={0}>
        <NavToolbar>
          <NavSection>
            <Logo />
            {user && (
              <>
                <NavLink href={ROUTES.redteam.dashboard} label="Dashboard" />
                <NavLink href={ROUTES.redteam.vulnerabilities} label="Vulnerabilities" />
                <NavLink href={ROUTES.redteam.report} label="Reports" />
                <NavLink href={ROUTES.eval} label="Evals" />
              </>
            )}
          </NavSection>
          <NavSection>
            <DarkMode onToggleDarkMode={onToggleDarkMode} />
            <LoggedInAs />
          </NavSection>
        </NavToolbar>
      </StyledAppBar>
    </>
  );
}
