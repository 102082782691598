import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { type Plugin, riskCategorySeverityMap, Severity } from '@promptfoo/redteam/constants';
import type { DashboardEvalDTO, IssuesOverTimeDTO } from '@shared/dto';
import AttackSuccessRateChart, { type AttackSuccessRateDataPoint } from './AttackSuccessRateChart';
import IssuesOverTimeChart from './IssuesOverTimeChart';
import ProbesChart, { type ProbesDataPoint } from './ProbesChart';

interface DashboardChartsProps {
  evals: DashboardEvalDTO[];
  issuesOverTimeData: IssuesOverTimeDTO;
}

function generateAttackSuccessRateOverTime(
  evals: DashboardEvalDTO[],
): AttackSuccessRateDataPoint[] {
  return evals
    ?.sort((a, b) => a.createdAt - b.createdAt)
    ?.map((eval_) => {
      const dataPoint: AttackSuccessRateDataPoint = {
        evalId: eval_.evalId || '',
        date: new Date(eval_.createdAt).toLocaleString(),
        [Severity.Critical]: 0,
        [Severity.High]: 0,
        [Severity.Medium]: 0,
        [Severity.Low]: 0,
        total: 0,
      };

      Object.entries(eval_.pluginFailCount || {}).forEach(([pluginId, count]) => {
        const severity = riskCategorySeverityMap[pluginId as Plugin] || Severity.Low;
        dataPoint[severity] += count;
        dataPoint.total += count;
      });

      return dataPoint;
    });
}

function generateProbesData(evals: DashboardEvalDTO[]): ProbesDataPoint[] {
  return evals
    ?.sort((a, b) => a.createdAt - b.createdAt)
    ?.map((eval_) => {
      const succeeded = Object.values(eval_.pluginFailCount || {}).reduce(
        (sum, count) => sum + count,
        0,
      );
      const failed = Object.values(eval_.pluginPassCount || {}).reduce(
        (sum, count) => sum + count,
        0,
      );

      return {
        evalId: eval_.evalId || '',
        date: new Date(eval_.createdAt).toLocaleString(),
        succeeded,
        failed,
        total: succeeded + failed,
      };
    });
}

export default function DashboardCharts({ evals, issuesOverTimeData }: DashboardChartsProps) {
  const [activeChart, setActiveChart] = useState<'attackSuccess' | 'openIssues' | 'probes'>(
    'attackSuccess',
  );

  const attackSuccessRateData = useMemo(() => {
    return generateAttackSuccessRateOverTime(evals);
  }, [evals]);

  const memoizedAttackSuccessChart = useMemo(
    () => <AttackSuccessRateChart data={attackSuccessRateData} />,
    [attackSuccessRateData],
  );

  const memoizedIssuesOverTimeChart = useMemo(
    () => <IssuesOverTimeChart data={issuesOverTimeData} />,
    [issuesOverTimeData],
  );

  const probesData = useMemo(() => {
    return generateProbesData(evals);
  }, [evals]);

  const memoizedProbesChart = useMemo(() => <ProbesChart data={probesData} />, [probesData]);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: 350,
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h6">
          {activeChart === 'attackSuccess'
            ? 'Attack Success'
            : activeChart === 'openIssues'
              ? 'Open Vulnerabilities'
              : 'Total Probes'}
        </Typography>
        <ToggleButtonGroup
          value={activeChart}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setActiveChart(newValue);
            }
          }}
          size="small"
        >
          <ToggleButton value="attackSuccess">Attacks</ToggleButton>
          <ToggleButton value="openIssues">Vulnerabilities</ToggleButton>
          <ToggleButton value="probes">Probes</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {activeChart === 'attackSuccess' ? (
        memoizedAttackSuccessChart
      ) : activeChart === 'openIssues' ? (
        memoizedIssuesOverTimeChart
      ) : activeChart === 'probes' ? (
        memoizedProbesChart
      ) : (
        <></>
      )}
    </Paper>
  );
}
