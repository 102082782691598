import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { getIssueEvalResults, getIssueRemediation } from '@cloud-ui/utils/api/issues';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Chip,
  Button,
  Divider,
  Tabs,
  Tab,
  Tooltip,
} from '@mui/material';
import { IssueStatus, type IssueStatusType } from '@shared/constants';
import type { IssueDTO } from '@shared/dto';
import ExplanationTab from './ExplanationTab';
import HistoryTab from './HistoryTab';
import SummaryTab from './SummaryTab';
import TestCasesTab from './TestCasesTab';

interface IssueProps {
  issue: IssueDTO;
  onClose: () => void;
  onIssueUpdate: (changes: Partial<IssueDTO>) => void;
  isUpdating: boolean;
}

export default function Issue({ issue, onClose, onIssueUpdate, isUpdating }: IssueProps) {
  const [activeTab, setActiveTab] = useState(0);
  const { showToast } = useToast();

  const {
    data: evalResults,
    isLoading: isEvalResultsLoading,
    isError: isEvalResultsError,
  } = useQuery({
    queryKey: ['issue_evalresults', issue.id],
    queryFn: () => getIssueEvalResults(issue.id),
  });

  const { data: remediation } = useQuery({
    queryKey: ['issue_remediation', issue.id],
    queryFn: () => getIssueRemediation(issue.id),
  });

  const handleStatusChange = (newStatus: IssueStatusType) => {
    onIssueUpdate({ status: newStatus });
  };

  const getActionButtons = () => {
    switch (issue.status) {
      case 'open':
        return (
          <>
            <Button
              variant="text"
              size="small"
              onClick={() => handleStatusChange(IssueStatus.FIXED)}
              startIcon={<CheckCircleOutlineIcon />}
              sx={{ color: 'success.main' }}
            >
              Mark as Fixed
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              variant="text"
              size="small"
              onClick={() => handleStatusChange(IssueStatus.IGNORED)}
              startIcon={<VisibilityOffIcon />}
              sx={{ color: 'warning.main' }}
            >
              Ignore
            </Button>
            <Divider orientation="vertical" flexItem />
            <Button
              variant="text"
              size="small"
              onClick={() => handleStatusChange(IssueStatus.FALSE_POSITIVE)}
              startIcon={<CancelOutlinedIcon />}
              sx={{ color: 'error.main' }}
            >
              False Positive
            </Button>
          </>
        );
      case 'fixed':
      case 'ignored':
      case 'false_positive':
        return (
          <Button
            variant="text"
            size="small"
            onClick={() => handleStatusChange(IssueStatus.OPEN)}
            startIcon={<CheckCircleOutlineIcon />}
            sx={{ color: 'primary.main' }}
          >
            Reopen
          </Button>
        );
    }
  };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        showToast('Link copied to clipboard', 'success');
      })
      .catch((err) => {
        showToast('Failed to copy link', 'error');
      });
  };

  return (
    <Paper elevation={3} sx={{ p: 3, position: 'relative', borderRadius: 2 }}>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mr: 1 }}>
          {issue.weakness}
        </Typography>
        <Tooltip title="Copy link to this issue">
          <IconButton onClick={handleCopyLink} size="small">
            <LinkIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Chip
          label={issue.status.charAt(0).toUpperCase() + issue.status.slice(1)}
          color="default"
          size="small"
          sx={{
            mr: 2,
            bgcolor: 'grey.300',
            color: issue.status === 'open' ? 'primary.main' : 'text.primary',
          }}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>{getActionButtons()}</Box>
      </Box>

      <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)} sx={{ mb: 2 }}>
        <Tab label="Summary" />
        <Tab label="Attacks" />
        <Tab label="History" />
        <Tab label="Explanation and Remediations" />
      </Tabs>

      {activeTab === 0 && (
        <SummaryTab
          issue={issue}
          evalResults={evalResults || []}
          isEvalResultsLoading={isEvalResultsLoading}
          isEvalResultsError={isEvalResultsError}
          onIssueUpdate={onIssueUpdate}
        />
      )}

      {activeTab === 1 && (
        <TestCasesTab
          evalResults={evalResults || []}
          isEvalResultsLoading={isEvalResultsLoading}
          isEvalResultsError={isEvalResultsError}
        />
      )}

      {activeTab === 2 && <HistoryTab issue={issue} />}
      {activeTab === 3 && <ExplanationTab remediation={remediation} />}
    </Paper>
  );
}
