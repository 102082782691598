import {
  AtomicTestCase,
  GradingResult,
  Prompt,
  ProviderOptions,
  ProviderResponse,
} from '@promptfoo/types';
import { z } from 'zod';
import validator from 'validator';
import { ALL_PLUGINS, Plugin } from '@promptfoo/redteam/constants';
import { IssueStatus, Severity, type IssueStatusType, type SeverityLevelType } from './constants';
export * from './dto/dashboard';
export * from './dto/config';

export const IssueHistoryTextSchema = z
  .string()
  .min(1)
  .max(5000)
  .transform((val) => validator.escape(val.trim()));

export const IssueHistoryDTOSchema = z.object({
  createdAt: z.date(),
  name: z.string(),
  text: IssueHistoryTextSchema,
  data: z.any().nullable(),
});

export const IssueDTOSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.date(),
  updatedAt: z.date(),
  pluginId: z.custom<Plugin>((val) => ALL_PLUGINS.includes(val as Plugin)),
  weakness: z.string(),
  status: z.enum(Object.values(IssueStatus) as [IssueStatusType, ...IssueStatusType[]]),
  organizationId: z.string(),
  severity: z.enum(Object.values(Severity) as [SeverityLevelType, ...SeverityLevelType[]]),
  targetId: z.string(),
  occurrences: z.number().int().min(0),
  history: z.array(IssueHistoryDTOSchema),
});

export type IssueDTO = z.infer<typeof IssueDTOSchema>;
export type IssueHistoryDTO = z.infer<typeof IssueHistoryDTOSchema>;

export const EvalResultDTOSchema = z.object({
  id: z.string().uuid(),
  evalId: z.string(),
  description: z.string().nullable().optional(),
  promptIdx: z.number(),
  testIdx: z.number(),
  testCase: z.custom<AtomicTestCase>(),
  prompt: z.custom<Prompt>(),
  promptId: z.string(),
  error: z.string().nullable().optional(),
  success: z.boolean(),
  score: z.number(),
  response: z.custom<ProviderResponse>().nullable(),
  gradingResult: z.custom<GradingResult>().nullable(),
  namedScores: z.record(z.string(), z.number()),
  provider: z.custom<ProviderOptions>(),
  latencyMs: z.number(),
  cost: z.number(),
  persisted: z.boolean(),
  createdAt: z.date(),
});

export type EvalResultDTO = z.infer<typeof EvalResultDTOSchema>;

export const RemediationDTOSchema = z.object({
  id: z.string().uuid(),
  remediation: z.string(),
  genericDescription: z.string(),
});

export type RemediationDTO = z.infer<typeof RemediationDTOSchema>;
