import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { getEvals, getOpenIssuesPerDay, getTargets } from '@cloud-ui/utils/api/dashboard';
import { fetchIssues } from '@cloud-ui/utils/api/issues';
import Dashboard from './components/Dashboard';
import { useDashboardStore } from './store';

export default function Page() {
  const { targetId } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { selectedTargetId, setSelectedTargetId } = useDashboardStore();

  const {
    data: targets,
    isPending: isPendingTargets,
    error: targetsError,
  } = useQuery({
    queryKey: ['targets'],
    queryFn: () => getTargets(),
  });

  if (targetsError) {
    console.error('Error getting targets', targetsError);
    showToast('Error getting targets', 'error');
  }

  const {
    data: evals,
    isPending: isPendingEvals,
    error: evalsError,
  } = useQuery({
    queryKey: ['dashboard', 'evals', selectedTargetId],
    queryFn: () => getEvals(selectedTargetId),
    enabled: !!selectedTargetId,
  });

  const {
    data: issuesPerDay,
    isPending: isPendingIssuesPerDay,
    error: issuesPerDayError,
  } = useQuery({
    queryKey: ['issuesPerDay', selectedTargetId],
    queryFn: () => getOpenIssuesPerDay(selectedTargetId),
    enabled: !!selectedTargetId,
  });

  useEffect(() => {
    if (evalsError) {
      console.error('Error getting evals', evalsError);
      showToast('Error getting evals', 'error');
    }
    if (issuesPerDayError) {
      console.error('Error getting issues per day', issuesPerDayError);
      showToast('Error getting issues per day', 'error');
    }
  }, [evalsError, issuesPerDayError]);

  useEffect(() => {
    const isValidTargetId = targetId && targets?.some((target) => target === targetId);

    if (!isPendingTargets && targetId) {
      if (isValidTargetId) {
        setSelectedTargetId(targetId);
      } else {
        console.error(`Invalid target ID: ${targetId}`);
        navigate(ROUTES.redteam.dashboard);
      }
    } else if (targets && targets.length > 0) {
      const targetIdToSelect = selectedTargetId ?? targets[0];
      setSelectedTargetId(targetIdToSelect);
      navigate(`${ROUTES.redteam.dashboard}/${targetIdToSelect}`);
    }
  }, [targets, targetId, selectedTargetId, navigate, isPendingTargets]);

  useEffect(() => {
    if (!isPendingTargets && (!targets || targets.length === 0)) {
      navigate(ROUTES.welcome);
    }
  }, [isPendingTargets, targets, navigate]);

  const {
    data: issues,
    isLoading: isPendingIssues,
    error: issuesError,
  } = useQuery({
    queryKey: ['issues'],
    queryFn: fetchIssues,
  });

  if (issuesError) {
    console.error('Error getting issues', issuesError);
    showToast('Error getting issues', 'error');
  }

  return (
    <Dashboard
      issues={issues || []}
      issuesPerDay={issuesPerDay || []}
      evals={evals || []}
      targets={targets || []}
      isLoading={isPendingTargets || isPendingIssuesPerDay || isPendingEvals || isPendingIssues}
    />
  );
}
