import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import PageShell from '@cloud-ui/components/PageShell';
import { ROUTES } from '@cloud-ui/constants';
import { AuthProvider, RequireAuth } from '@cloud-ui/contexts/AuthContext';
import { ConfigProvider } from '@cloud-ui/contexts/ConfigContext';
import { ToastProvider } from '@cloud-ui/contexts/ToastContext';
import NotFoundPage from '@cloud-ui/pages/NotFound';
import LoginPage from '@cloud-ui/pages/auth/login/Login';
import ResultsPage from '@cloud-ui/pages/eval/page';
import OrganizationPage from '@cloud-ui/pages/organization';
import RedteamDashboardPage from '@cloud-ui/pages/redteam/dashboard/page';
import RedteamReportPage from '@cloud-ui/pages/redteam/report/page';
import WelcomePage from '@cloud-ui/pages/welcome/page';
import IssuesPage from './pages/redteam/issues/page';
import VerifyEmailPage from './pages/verify-email';

const DEFAULT_ROUTE = ROUTES.redteam.dashboard;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});
const ProtectedRoutes = () => (
  <RequireAuth>
    <Outlet />
  </RequireAuth>
);
const SentryRoutes = Sentry.withSentryRouting(Routes);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      <ConfigProvider>
        <AuthProvider>
          <ToastProvider>
            <Router>
              <PageShell>
                <SentryRoutes>
                  <Route path="/" element={<Navigate to={DEFAULT_ROUTE} replace />} />

                  {/* Public routes */}
                  <Route path={ROUTES.login} element={<LoginPage />} />
                  <Route path={ROUTES.verifyEmail} element={<VerifyEmailPage />} />

                  {/* Protected routes */}
                  <Route element={<ProtectedRoutes />}>
                    <Route path={ROUTES.welcome} element={<WelcomePage />} />
                    <Route path={ROUTES.eval} element={<ResultsPage />} />
                    <Route path={`${ROUTES.eval}/:id`} element={<ResultsPage />} />
                    <Route path={`${ROUTES.organization}/:orgId`} element={<OrganizationPage />} />
                    <Route path={ROUTES.redteam.dashboard} element={<RedteamDashboardPage />} />
                    <Route
                      path={`${ROUTES.redteam.dashboard}/:targetId`}
                      element={<RedteamDashboardPage />}
                    />
                    <Route path={ROUTES.redteam.report} element={<RedteamReportPage />} />
                    <Route path={`${ROUTES.redteam.report}/:id`} element={<RedteamReportPage />} />
                    <Route path={ROUTES.redteam.vulnerabilities} element={<IssuesPage />} />
                    <Route
                      path={`${ROUTES.redteam.vulnerabilities}/:issueId`}
                      element={<IssuesPage />}
                    />
                  </Route>

                  {/* Catch-all route for 404 Not Found */}
                  <Route path="*" element={<NotFoundPage />} />
                </SentryRoutes>
              </PageShell>
            </Router>
          </ToastProvider>
        </AuthProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
