import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState, useCallback } from 'react';
import { useToast } from '@cloud-ui/contexts/ToastContext';
import { updateIssue } from '@cloud-ui/utils/api/issues';
import { getUniqueTargets, getUniqueVulnerabilities } from '@cloud-ui/utils/issues';
import { Box, Typography, type SelectChangeEvent, Paper } from '@mui/material';
import { green, orange, red, blue } from '@mui/material/colors';
import { riskCategories, type TopLevelCategory } from '@promptfoo/redteam/constants';
import {
  IssueStatus,
  type IssueStatusType,
  Severity,
  SeverityOrder,
  type SeverityLevelType,
} from '@shared/constants';
import type { IssueDTO } from '@shared/dto';
import { useIssueFiltersStore, type IssueFilters } from '../store';
import ErrorMessage from './ErrorMessage';
import FilterBar from './FilterBar';
import IssueList from './IssueList';
import LoadingSpinner from './LoadingSpinner';
import Issue from './issue/Issue';

export const severityColors: Record<SeverityLevelType, string> = {
  [Severity.Low]: green[500],
  [Severity.Medium]: orange[500],
  [Severity.High]: red[500],
  [Severity.Critical]: red[900],
};

export const statusColors: Record<IssueStatusType, string> = {
  [IssueStatus.OPEN]: blue[500],
  [IssueStatus.FIXED]: green[500],
  [IssueStatus.IGNORED]: orange[500],
  [IssueStatus.FALSE_POSITIVE]: red[500],
};

export default function Issues({
  issues,
  isLoading,
  error,
  selectedIssueId,
  filters: initialFilters,
}: {
  issues: IssueDTO[];
  isLoading: boolean;
  error: string;
  selectedIssueId?: string;
  filters: {
    targetId: string | null;
    severity: string | null;
    weakness: string | null;
    riskCategory: string | null;
    status: string | null;
  };
}) {
  const { filters, setFilter, clearFilters } = useIssueFiltersStore();

  useEffect(() => {
    // Set the filters that come from URL params
    if (
      initialFilters.targetId ||
      initialFilters.severity ||
      initialFilters.weakness ||
      initialFilters.riskCategory ||
      initialFilters.status
    ) {
      clearFilters();
    }

    if (initialFilters.targetId) {
      setFilter('target', initialFilters.targetId);
    }
    if (initialFilters.severity) {
      setFilter('severity', initialFilters.severity);
    }
    if (initialFilters.weakness) {
      setFilter('weakness', initialFilters.weakness);
    }
    if (initialFilters.riskCategory) {
      setFilter('riskCategory', initialFilters.riskCategory);
    }
    if (initialFilters.status) {
      setFilter('status', initialFilters.status);
    }
  }, [initialFilters, clearFilters, setFilter]);

  const [selectedIssue, setSelectedIssue] = useState<IssueDTO | null>(null);

  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const [filteredRows, setFilteredRows] = useState<IssueDTO[]>([]);

  useEffect(() => {
    const newFilteredRows = issues.filter(
      (row) =>
        (filters.target === '' || row.targetId.toLowerCase() === filters.target.toLowerCase()) &&
        (filters.severity === '' ||
          row.severity.toLowerCase() === filters.severity.toLowerCase()) &&
        (filters.weakness === '' ||
          row.weakness.toLowerCase().includes(filters.weakness.toLowerCase())) &&
        (filters.status === '' || row.status.toLowerCase() === filters.status.toLowerCase()) &&
        (filters.riskCategory === '' ||
          riskCategories[filters.riskCategory as TopLevelCategory]?.includes(row.pluginId)),
    );
    setFilteredRows(newFilteredRows);
  }, [issues, filters]);

  const uniqueTargets = getUniqueTargets(issues);
  const uniqueVulnerabilities = getUniqueVulnerabilities(issues);

  const handleCloseDetails = () => {
    setSelectedIssue(null);
  };

  const updateIssueMutation = useMutation({
    mutationFn: (changes: Partial<IssueDTO>) =>
      selectedIssue ? updateIssue(selectedIssue.id, changes) : Promise.reject('No issue selected'),
    onSuccess: (updatedIssue) => {
      showToast('Issue updated', 'success');
      setSelectedIssue(updatedIssue);
      // Update the issue in the issues list cache
      queryClient.setQueryData(['issues'], (oldIssues: IssueDTO[] | undefined) => {
        if (!oldIssues) {
          return oldIssues;
        }
        const newIssues = oldIssues.map((i) => (i.id === updatedIssue.id ? updatedIssue : i));
        return newIssues.sort((a, b) => {
          return SeverityOrder.indexOf(b.severity) - SeverityOrder.indexOf(a.severity);
        });
      });
    },
    onError: (error) => {
      showToast('Error updating issue', 'error');
      console.error('Error updating issue:', error);
    },
  });

  const handleIssueUpdate = useCallback(
    (changes: Partial<IssueDTO>) => {
      if (selectedIssue) {
        updateIssueMutation.mutate(changes);
      }
    },
    [selectedIssue, updateIssueMutation],
  );

  useEffect(() => {
    // Don't proceed if there are no filtered issues
    if (filteredRows.length === 0) {
      return;
    }

    // Case 1: Select issue by ID if provided
    if (selectedIssueId) {
      const issue = filteredRows.find((issue) => issue.id === selectedIssueId);
      if (issue) {
        setSelectedIssue(issue);
      }
      return;
    }

    // Case 2: Auto-select the only issue if there's exactly one
    if (filteredRows.length === 1) {
      setSelectedIssue(filteredRows[0]);
    }
  }, [selectedIssueId, issues, filteredRows]);

  const handleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
  ) => {
    const name = event.target.name as keyof IssueFilters;
    const value = event.target.value;
    setFilter(name, value);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorMessage message={error} />;
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 100px)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">Vulnerabilities ({filteredRows.length}) </Typography>
        </Box>
        <FilterBar
          filters={filters}
          uniqueTargets={uniqueTargets}
          uniqueVulnerabilities={uniqueVulnerabilities}
          onFilterChange={handleFilterChange}
          onClearFilters={clearFilters}
        />
      </Box>
      <Box sx={{ display: 'flex', flex: 1, overflow: 'auto' }}>
        <Box sx={{ width: selectedIssue ? '30%' : '100%', pr: 2 }}>
          {filteredRows.length === 0 ? (
            <Paper
              sx={{
                p: 4,
                textAlign: 'center',
                backgroundColor: 'background.default',
              }}
            >
              <Typography variant="h6" color="text.secondary" gutterBottom>
                No vulnerabilities found
              </Typography>
              <Typography color="text.secondary">
                {filters.target ||
                filters.severity ||
                filters.weakness ||
                filters.status ||
                filters.riskCategory
                  ? 'Try adjusting your filters to see more results'
                  : 'No vulnerabilities have been detected yet'}
              </Typography>
            </Paper>
          ) : (
            <IssueList
              issues={filteredRows}
              isCompact={!!selectedIssue}
              selectedIssue={selectedIssue}
            />
          )}
        </Box>
        {selectedIssue && (
          <Box sx={{ width: '70%', borderLeft: '1px solid #ccc', pl: 2 }}>
            <Issue
              issue={selectedIssue}
              onClose={handleCloseDetails}
              onIssueUpdate={handleIssueUpdate}
              isUpdating={updateIssueMutation.isPending}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
