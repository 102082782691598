import { useMemo } from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Chip,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  type Strategy,
  pluginDescriptions,
  strategyDescriptions,
  strategyDisplayNames,
} from '@promptfoo/redteam/constants';
import { Severity, type SeverityLevelType } from '@shared/constants';
import type { EvalResultDTO, IssueDTO } from '@shared/dto';

// Add this styled component for alternating row colors
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface SummaryTabProps {
  issue: IssueDTO;
  evalResults: EvalResultDTO[];
  isEvalResultsLoading: boolean;
  isEvalResultsError: boolean;
  onIssueUpdate: (changes: Partial<IssueDTO>) => void;
}

export default function SummaryTab({
  issue,
  evalResults,
  isEvalResultsLoading,
  isEvalResultsError,
  onIssueUpdate,
}: SummaryTabProps) {
  const handleSeverityChange = (newSeverity: SeverityLevelType) => {
    onIssueUpdate({ severity: newSeverity });
  };

  const getSeverityColor = (severity: SeverityLevelType) => {
    switch (severity) {
      case Severity.Low:
        return 'success';
      case Severity.Medium:
        return 'warning';
      case Severity.High:
        return 'error';
      case Severity.Critical:
        return 'error';
      default:
        return 'default';
    }
  };

  const strategyBreakdown = useMemo<Record<Strategy, number>>(() => {
    if (!evalResults) {
      return {} as Record<Strategy, number>;
    }
    return evalResults.reduce(
      (acc, result) => {
        const strategyId = result.testCase.metadata?.strategyId as Strategy;
        if (strategyId) {
          acc[strategyId] = (acc[strategyId] || 0) + 1;
        } else {
          acc['basic'] = (acc['basic'] || 0) + 1;
        }
        return acc;
      },
      {} as Record<Strategy, number>,
    );
  }, [evalResults]);

  const generateSummary = () => {
    const pluginDescription = pluginDescriptions[issue.pluginId] || 'Unknown vulnerability';
    const strategies = Object.keys(strategyBreakdown);

    return (
      <>
        <Typography variant="body1">
          This target is susceptible to {issue.weakness}. {pluginDescription}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
          This vulnerability was successfully exploited using the following strategies:
        </Typography>
        <List>
          {strategies.map((strategy) => {
            const displayName = strategyDisplayNames[strategy as Strategy];
            const description =
              strategyDescriptions[strategy as Strategy] || 'No description available';
            return (
              <ListItem key={strategy}>
                <ListItemText
                  primary={displayName}
                  secondary={description}
                  primaryTypographyProps={{ fontWeight: 'medium' }}
                />
              </ListItem>
            );
          })}
        </List>
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ typography: 'body1' }}>{generateSummary()}</Box>
      <Table>
        <TableBody>
          <StyledTableRow>
            <TableCell component="th" scope="row" sx={{ width: '30%', fontWeight: 'bold' }}>
              Target
            </TableCell>
            <TableCell sx={{ pl: 2 }}>{issue.targetId}</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
              First Seen
            </TableCell>
            <TableCell sx={{ pl: 2 }}>{new Date(issue.createdAt).toLocaleString()}</TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
              Last Seen
            </TableCell>
            <TableCell sx={{ pl: 2 }}>
              {evalResults?.[0]
                ? new Date(evalResults[0].createdAt).toLocaleString()
                : 'Not seen yet'}
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
              Severity
            </TableCell>
            <TableCell sx={{ pl: 2 }}>
              <FormControl size="small" sx={{ minWidth: 120 }}>
                <Select
                  value={issue.severity}
                  onChange={(e) => handleSeverityChange(e.target.value as SeverityLevelType)}
                  renderValue={(value) => (
                    <Chip
                      label={value.charAt(0).toUpperCase() + value.slice(1)}
                      color={getSeverityColor(value as SeverityLevelType)}
                      size="small"
                    />
                  )}
                >
                  {Object.values(Severity).map((level) => (
                    <MenuItem key={level} value={level}>
                      {level.charAt(0).toUpperCase() + level.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
          </StyledTableRow>
          <StyledTableRow>
            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold' }}>
              Strategy Breakdown
            </TableCell>
            <TableCell sx={{ pl: 2 }}>
              {isEvalResultsLoading ? (
                <Box display="flex" alignItems="center">
                  <CircularProgress size={20} />
                  <Typography sx={{ ml: 1 }}>Loading strategy breakdown...</Typography>
                </Box>
              ) : isEvalResultsError ? (
                <Typography color="error">Error loading strategy breakdown</Typography>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {Object.entries(strategyBreakdown).map(([strategy, count]) => (
                    <Typography key={strategy} variant="body2">
                      {strategy}: {count} failure{count === 1 ? '' : 's'}
                    </Typography>
                  ))}
                </Box>
              )}
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
