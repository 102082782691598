import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cloud-ui/constants';
import { Severity } from '@promptfoo/redteam/constants';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
} from 'recharts';

export interface AttackSuccessRateDataPoint {
  date: string;
  evalId: string;
  [Severity.Critical]: number;
  [Severity.High]: number;
  [Severity.Medium]: number;
  [Severity.Low]: number;
  total: number;
}

interface AttackSuccessRateChartProps {
  data: AttackSuccessRateDataPoint[];
}

export default function AttackSuccessRateChart({ data }: AttackSuccessRateChartProps) {
  const navigate = useNavigate();

  const sortedData = useMemo(() => {
    if (!data) {
      return [];
    }
    return [...data].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [data]);

  const severityColors = {
    [Severity.Critical]: '#d32f2f',
    [Severity.High]: '#f57c00',
    [Severity.Medium]: '#fbc02d',
    [Severity.Low]: '#388e3c',
  };

  const handleDotClick = (severity: Severity, dataPoint: any) => {
    if (dataPoint.payload.evalId) {
      navigate(`${ROUTES.redteam.report}/${dataPoint.payload.evalId}`);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={sortedData}>
        <CartesianGrid strokeDasharray="3 3" />

        {/* Add ReferenceLines for each data point */}
        {sortedData.map((dataPoint, index) => (
          <ReferenceLine
            key={`${dataPoint.date}-${index}`}
            x={dataPoint.date}
            stroke="#666"
            strokeWidth={1}
            strokeDasharray="3 3"
          />
        ))}

        <XAxis
          dataKey="date"
          tick={{ fontSize: 10 }}
          type="category"
          tickFormatter={(value) => new Date(value).toLocaleDateString()}
          ticks={[...new Set(sortedData.map((d) => new Date(d.date).toLocaleDateString()))]} // Only show unique dates
          interval={0} // Show all unique date ticks
          minTickGap={50}
        />
        <YAxis width={40} domain={[0, 'dataMax']} tick={{ fontSize: 12 }} />
        <Tooltip
          formatter={(value: number, name: string) => {
            return [value, name === 'total' ? 'Total' : name];
          }}
        />
        <Legend />
        {Object.values(Severity).map((severity) => (
          <Area
            key={severity}
            type="monotone"
            dataKey={severity}
            stackId="1"
            stroke={severityColors[severity]}
            fill={severityColors[severity]}
            isAnimationActive={false}
            dot={{
              stroke: severityColors[severity],
              strokeWidth: 3,
              r: 2,
              fill: 'white',
              cursor: 'pointer',
              onClick: (_, payload) =>
                handleDotClick(severity, payload as unknown as AttackSuccessRateDataPoint),
            }}
            activeDot={{
              stroke: severityColors[severity],
              strokeWidth: 3,
              r: 3,
              fill: 'white',
              cursor: 'pointer',
              onClick: (_, payload) =>
                handleDotClick(severity, payload as unknown as AttackSuccessRateDataPoint),
            }}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
}
